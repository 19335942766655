var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "title": ("Form " + (_vm.label))
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$emit('submit', this.form);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.labelType == "reimburse" ? "Claim Reimburse" : "Kirim ke Finance") + " ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nominal"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.form.nominal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nominal", $$v);
      },
      expression: "form.nominal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }